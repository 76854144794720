body {
  background: no-repeat center center fixed; 
  background-color: #0c0d12;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: radial-gradient(circle, #7400ff -80%, transparent 70%);
}

.connectBox {
  margin-bottom: 40px !important;
}

@media (max-width: 768px) { /* Adjust this value based on your needs */
  nav ul {
    justify-content: left!important; /* Align items to the left on mobile devices */
  }
}

.App {
  text-align: center;
  max-width: 1040px;
  margin: 0 auto;
  padding: 20px;
}

img {
  margin-bottom: 0px !important;
  box-shadow: 0 -20px 20px #00000026!important;
}

img:hover {
  box-shadow: 0 -30px 30px #00000026!important;
}

.prevButton {
  margin-top: 20px;
}

.customLink {
  color: #ffc700;
}


.jsonData {
  font-size: 11px;
  overflow: auto;
  max-height: 700px;
}



.box {
  background: #191923!important;
  border-radius: 5px;
  box-shadow: 0 -20px 20px #00000026!important;
  padding: 20px;
}

.uploadButton {
  margin-left: 20px;
}

nav ul {
  list-style-type: none;
  padding: 5px !important;
  border-radius: 5px;
  box-shadow: 0 -20px 20px #00000026!important;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

.custom-spinner-color2 {
display: none !important;
}

.App {
  text-align: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  background: #191923!important;
}

nav ul li {
  margin: 10px;
}

nav ul li a {
  text-decoration: none;
  color: #f5f7fa!important; 
  font-weight: bold;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.3s ease;
} 

nav ul li a:hover {
  background-color: #22242b!important; 
  color: #ffc700!important; 
  border-color: #ffc700; 
  border: 1px solid;
}


.wallet-adapter-button-trigger {
  background-color: #4f46e5!important;
  color: white; 
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  height: 40px;
  margin-top: 10px !important;
}

.wallet-adapter-button {
  height: 40px;
  margin-top: 10px !important;
  border: 0px!important;
}

.wallet-adapter-button:hover {
  border: 0px!important;
}

.wallet-adapter-button-trigger:hover {
  background-color: #4f46e5; 
  border: 2px;
  border-color: #ffc700;
}

.wallet-adapter-dropdown-list {
z-index: 1000;
}

.wallet-adapter-dropdown {
  z-index: 1000;
}

.MuiButtonBase-root {
  text-decoration: none;
  border: 1px solid transparent !important;
  color: #f5f7fa!important; 
  font-weight: bold;
  padding: 10px;
  margin: 10px !important;
  border-radius: 5px;
  transition: all 0.3s ease;
  }

  .MuiButtonBase-root:hover {
    background-color: #22242b!important; 
    color: #ffc700!important; 
    border-color: #ffc700; 
    border: 1px solid;
  }


  button {
    font-weight: bold!important;
    border: 1px solid transparent !important;
    background-color: #232429!important;
    color: #ffc700!important; 
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    outline: none;
    margin: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  button:hover {
    background-color: #22242b!important; 
    border: 1px solid !important;
    color: #ffc700!important; 
    border-color: #ffc700; 
  }

  
/*  */

.nft-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the items horizontally */
    gap: 20px; /* Space between the items */
    padding: 20px; /* Padding around the grid */
}

@media (max-width: 768px) {
  .nft-grid {
      grid-template-columns: 1fr; 
  }
}

.nft-item {
  cursor: pointer;
  max-width: 200px!important;
  margin: 0!important;
  padding: 0!important;
}


.nft-grid {
  margin-bottom: 40px!important;
  margin-top: 20px!important;
}

/* links */

a {
  font-size: 0.95rem !important;
}

h1 {
  color: #f5f7fa;
}

h2 {
  color: #f5f7fa!important; 
  font-size: 1.2rem !important;
}


.custom-spinner-color {
  color: #ffc700!important; 
}

.wallet-adapter-button {
  background: none!important;
}

.wallet-adapter-modal-title {
color: white!important;
}

.wallet-adapter-button {
  max-width: 90%!important;
}

.wallet-adapter-button-trigger {
  color: white!important;
  background: #4f46e5!important;
}

.wallet-adapter-button * {
  box-shadow: none!important;
}

.wallet-adapter-modal-list-more {
  background: none!important;
}
.wallet-adapter-dropdown-list-item {
  width: auto!important;
}

.wallet-adapter-modal-button-close {
  background: none!important;
}

/* globalStyles.css */

/* Define the TTS font */
@font-face {
  font-family: 'TTS';
  src: url('/src/TTS.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Apply the font globally */
body, h1, h2, h3, h4, h5, h6, p, a, span, div, button, input, textarea, button, label {
  font-family: 'TTS', sans-serif!important;
}

.App {
  font-family: 'TTS', sans-serif;
}

/* 


*/

.nav-header {
  height: 82px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-sizing: border-box;
  z-index: 99;
}

.nav-content {
  width: 100%;
  max-width: 1620px;
  height: 82px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;
  position: fixed;
  padding: 0 24px;
  box-sizing: border-box;
}

@media all and (max-width: 1380px) {
  .nav-content {
      gap: 18px;
  }
}

@media all and (max-width: 940px) {
  .nav-content {
      justify-content: space-between;
  }
}

.nav-content .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.nav-content .logo img {
  height: 37px;
}

.nav-content .spacer {
  flex: 1;
}

@media all and (max-width: 940px) {
  .nav-content .spacer {
      display: none;
  }
}

.nav-content .search {
  flex: 1;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 0 10px;
  border-radius: 12px;
  box-sizing: border-box;
  gap: 10px;
  border: 1px solid #22242B;
}

@media all and (max-width: 940px) {
  .nav-content .search {
      width: auto;
      display: none;
  }
}

.nav-content .search .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-content .search .icon img {
  width: 24px;
  height: 24px;
}

.nav-content .search input {
  flex: 1;
  background-color: transparent;
  font-family: 'TT Supermolot Neue';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.26px;
  color: rgba(255, 255, 255, 1);
  padding: 0;
  border: 0;
  outline: none;
}

.nav-content .search input::placeholder {
  color: rgba(245, 247, 250, 0.4);
}

.nav-content .links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 42px;
}

@media all and (max-width: 1380px) {
  .nav-content .links {
      gap: 18px;
  }
}

@media all and (max-width: 940px) {
  .nav-content .links {
      width: auto;
      display: none;
  }
}

.nav-content .links a {
  font-family: 'TT Supermolot Neue';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 121%;
  letter-spacing: 0.26px;
  color: #F5F7FA;
  text-decoration: none;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.15s ease-in;
  cursor: pointer;
}

.nav-content .links a:active {
  transform: scale(0.96);
}

.nav-content .links a:hover {
  opacity: 1;
}

.nav-content .btn-theme {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-content .btn-theme img {
  width: 100%;
}

.nav-content .wallet {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border-radius: 12px;
  border: 1px solid #22242B;
  box-sizing: border-box;
  transition: all 0.1s ease-in;
  cursor: pointer;
}

.nav-content .wallet:active {
  transform: scale(0.98);
}

.nav-content .wallet .connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.nav-content .wallet .connect.disabled {
  display: none;
}

.nav-content .wallet .connect .icon {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #181923;
  border-radius: 12px;
}

.nav-content .wallet .connect .icon img {
  width: 21px;
  height: 21px;
}

.nav-content .wallet .connect span {
  font-family: 'TT Supermolot Neue';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 121%;
  letter-spacing: 0.26px;
  color: #F5F7FA;
  margin-right: 9px;
  white-space: nowrap;
}

.nav-content .wallet .connected {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.nav-content .wallet .connected.disabled {
  display: none;
}

.nav-content .wallet .connected .icon {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #181923;
  border-radius: 12px;
}

.nav-content .wallet .connected .icon img {
  width: 21px;
  height: 21px;
}

.nav-content .wallet .connected span {
  font-family: 'TT Supermolot Neue';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 121%;
  letter-spacing: 0.26px;
  color: #F5F7FA;
  white-space: nowrap;
}

.nav-content .wallet .connected .btn-menu {
  width: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-content .wallet .connected .btn-menu img {
  width: 20px;
  height: 20px;
}

.box {
  --swv-button-background-color: #4f46e5;
  --swv-button-text-color: white;
  --swv-button-outline-color: #3730a3;
  --swv-button-hover-background-color: #4338ca;
  --swv-button-disabled-background-color: #475569;
  --swv-button-disabled-text-color: #94a3b8;
  --swv-overlay-background-color: rgba(200, 200, 200, .8);
  --swv-modal-background-color: white;
  --swv-modal-text-color: #1f2937;
  --swv-modal-close-button-background-color: #f3f4f6;
  --swv-modal-close-button-text-color: #9ca3af;
  --swv-modal-close-button-hover-text-color: #374151;
  --swv-modal-button-text-color: #1f2937;
  --swv-modal-button-hover-background-color: #f3f4f6;
  --swv-modal-button-outline-color: var(--swv-button-outline-color);
  --swv-dropdown-background-color: white;
  --swv-dropdown-item-hover-background-color: #f3f4f6;
  --swv-dropdown-text-color: #1f2937;
  --swm-modal-shadow: 0 25px 25px rgb(0 0 0 / .15);
  --swm-dropdown-shadow: 0px 10px 20px rgba(0, 0, 0, .3);
  --swv-button-radius: 6px;
  --swv-modal-radius: 10px;
  --swv-dropdown-radius: 10px;
  --swv-dropdown-item-radius: 6px;
  font-family: TT Supermolot Neue,Arial,-apple-system,BlinkMacSystemFont,Helvetica Neue,sans-serif;
  line-height: 1.154;
  font-weight: 400;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  --vc-clr-primary: #000;
  --vc-clr-secondary: #090f207f;
  --vc-clr-white: #ffffff;
  --vc-icn-width: 1.2em;
  --vc-nav-width: 30px;
  --vc-nav-height: 30px;
  --vc-nav-border-radius: 0;
  --vc-nav-color: var(--vc-clr-primary);
  --vc-nav-color-hover: var(--vc-clr-secondary);
  --vc-nav-background: transparent;
  --vc-pgn-width: 12px;
  --vc-pgn-height: 4px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
  width: 100%;
  max-width: 1620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  z-index: 1;
}

.guide-text {
  color: #f3f4f6!important;
}


/* Main content container to manage the layout */
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px; /* Adjust this value as needed to ensure proper spacing below the fixed nav */
  padding-bottom: 20px;
}

/* Style for the main header */
.main-header {
  text-align: center;
  color: #ffc700!important;
  font-size: 24px;
  margin-bottom: 20px; /* Space between the header and the box */
}

/* Example responsive adjustments */
@media (max-width: 768px) {
  .main-header {
      font-size: 18px;
  }

  .main-content {
      padding-top: 40px; /* Adjust the padding to fit smaller screens */
  }
}
